import '../css/style.css';

function App() {
    return (
        <footer className=" text-center text-lg-start footer">
            <div className="text-center p-4">Copyright © 2022 : Grouporama</div>
        </footer>
    );
}

export default App;
